import cn from 'classnames';
import { SM_FLEX_CENTER_ALL_ADAPTIVE } from 'src/utils/tailwindStyles';
import { ControlPanel } from '../ControlPanel';
import { useState } from 'react';

const PANEL_SIDE = 'border-black w-full h-full flex flex-col justify-end';
const CONTROL_PANEL = 'border-t-2 border-black py-2';

const Battlefield = ({ masterMode }) => {
  const [enemyPower, setEnemyPower] = useState(0);
  const [playerPower, setPlayerPower] = useState(0);

  return (
    <div
      className={cn(
        'flex-row w-2/4 hidden border-black border-x-2',
        SM_FLEX_CENTER_ALL_ADAPTIVE
      )}
    >
      <>
        <div className={cn('border-r', PANEL_SIDE)}>
          <div className="h-full"></div>
          {masterMode && (
            <ControlPanel
              className={CONTROL_PANEL}
              power={enemyPower}
              setPower={setEnemyPower}
            />
          )}
        </div>
        <div className={cn('border-l', PANEL_SIDE)}>
          <div className="h-full"></div>
          {masterMode && (
            <ControlPanel
              className={CONTROL_PANEL}
              power={playerPower}
              setPower={setPlayerPower}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default Battlefield;
