import Icon from 'src/components/common/Icon';
import { FLEX_CENTER_ALL } from 'src/utils/tailwindStyles';
import cn from 'classnames';

const ControlPanel = ({ power, setPower, className }) => {
  const CARD_POWER_BUTTONS = cn('cursor-pointer w-6 h-6', FLEX_CENTER_ALL);

  const powerUp = () => {
    setPower((prev) => prev + 1);
  };

  const powerDown = () => {
    setPower((prev) => prev - 1);
  };

  return (
    <div className={cn('flex justify-around w-full', className)}>
      <button className={CARD_POWER_BUTTONS} onClick={powerDown}>
        <Icon type="minus_bordered" />
      </button>
      {power}
      <button className={CARD_POWER_BUTTONS} onClick={powerUp}>
        <Icon type="plus_bordered" />
      </button>
    </div>
  );
};

const ControlPanelMobile = ({ power, setPower, className }) => {
  const CARD_POWER_BUTTONS = cn(
    'cursor-pointer w-full h-full',
    FLEX_CENTER_ALL,
    'border-black border-t-2 border-r border-l border-b'
  );

  const powerUp = () => {
    setPower((prev) => prev + 1);
  };

  const powerDown = () => {
    setPower((prev) => prev - 1);
  };

  return (
    <div className={cn('flex flex-col items-center w-full', className)}>
      <div
        className={cn(
          'text-9xl py-9 border-black border-t-2 w-full',
          FLEX_CENTER_ALL
        )}
      >
        {power}
      </div>
      <div className="grid grid-cols-2 w-full h-full">
        <button className={CARD_POWER_BUTTONS} onClick={powerDown}>
          <Icon type="minus" />
        </button>
        <button className={CARD_POWER_BUTTONS} onClick={powerUp}>
          <Icon type="plus" />
        </button>
      </div>
    </div>
  );
};

export { ControlPanelMobile, ControlPanel };
