import { createPool } from '@vercel/postgres';
import postgresConnectionConfig from '../postgresConnectionConfig';

const mutatePlayer = async (newPlayerData) => {
  let { id, name, power, alive } = newPlayerData;
  if (!alive) {
    alive = true;
  }
  const pool = createPool(postgresConnectionConfig);

  const response =
    await pool.sql`UPDATE players SET name=${name}, power=${power}, alive=${alive} WHERE id=${id};`;
  pool.end();
  return response;
};

export default mutatePlayer;
