import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useState,
} from 'react';
import getAllPlayers from 'src/utils/data/players/getAllPlayers';

const cloneWithProps = (children, props) => {
  return Children.map(children, (child) => {
    if (!isValidElement(child)) return child;

    const newChild = cloneElement(
      child,
      props,
      cloneWithProps(child.props.children, props)
    );
    return newChild;
  });
};

const defaultPlayers = [
  { id: 1, name: 'Новый игрок', power: 0, isPlayerNew: true },
  { id: 2, name: 'Новый игрок', power: 0, isPlayerNew: true },
  { id: 3, name: 'Новый игрок', power: 0, isPlayerNew: true },
  { id: 4, name: 'Новый игрок', power: 0, isPlayerNew: true },
  { id: 5, name: 'Новый игрок', power: 0, isPlayerNew: true },
  { id: 6, name: 'Новый игрок', power: 0, isPlayerNew: true },
];

const ACTIONS = {
  GET: 'get',
  LOADING: 'loading',
  WAIT: 'wait',
};

const { GET, LOADING, WAIT } = ACTIONS;

const PostgresDataProvider = ({ children }) => {
  const [action, dispatch] = useState(GET);
  const [players, setPlayers] = useState(defaultPlayers);

  const updatePlayers = () => {
    dispatch(GET);
  };

  const pullAndSetPlayers = () => {
    getAllPlayers().then((data) => {
      const filledPlayersData = defaultPlayers
        .map((defaultPlayer) => {
          const findPlayerWithSameId = data.find(
            (player) => player.id === defaultPlayer.id
          );
          if (findPlayerWithSameId) {
            return findPlayerWithSameId;
          } else {
            return defaultPlayer;
          }
        })
        .sort((a, b) => a.id - b.id);

      setPlayers(filledPlayersData);
      dispatch(WAIT);
    });
  };

  useEffect(() => {
    if (action === GET) {
      pullAndSetPlayers();
      dispatch(LOADING);
    }
  }, [action]);

  return (
    <>{cloneWithProps(children, { players, status: action, updatePlayers })}</>
  );
};

export { PostgresDataProvider, ACTIONS, defaultPlayers };
