import { useState } from 'react';
import { FLEX_CENTER_ALL, INPUT_HOVER } from 'src/utils/tailwindStyles';
import cn from 'classnames';

const EditableLabel = ({
  inputValue,
  setInputValue,
  inpunClassName,
  labelClassName,
}) => {
  const [editing, setEditing] = useState(false);
  const changeEditing = () => setEditing((prev) => !prev);

  const setNameless = () => {
    if (inputValue === '') {
      setInputValue('Безымянный');
    }
  };

  const inputOnChange = (e) => setInputValue(e.target.value);
  const inputOnBlur = () => {
    setNameless();
    changeEditing();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setNameless();
      changeEditing();
    }
  };

  return (
    <>
      {!editing ? (
        <input
          className={cn(
            'text-center w-full p-1 focus-visible:border-black focus-visible:border-y-2 focus-visible:outline-none',
            FLEX_CENTER_ALL,
            INPUT_HOVER,
            inpunClassName
          )}
          onKeyDown={handleKeyDown}
          onBlur={inputOnBlur}
          value={inputValue}
          onChange={inputOnChange}
        />
      ) : (
        <label
          className={cn(
            'cursor-pointer w-full py-1 text-center hover:border-black hover:border-y-2',
            FLEX_CENTER_ALL,
            labelClassName
          )}
          onClick={changeEditing}
        >
          {inputValue}
        </label>
      )}
    </>
  );
};

export default EditableLabel;
