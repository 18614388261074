import cn from 'classnames';

import Icon from 'src/components/common/Icon';
import { BUTTON_HOVER } from 'src/utils/tailwindStyles';

const ModalCloseButton = ({
  disabled,
  containerClassName,
  buttonClassName,
  iconClassName,
  onClick,
}) => {
  return (
    <div
      className={cn('flex justify-end w-full relative z-0', containerClassName)}
    >
      <button
        disabled={disabled}
        className={cn(buttonClassName, BUTTON_HOVER)}
        onClick={onClick}
      >
        <Icon
          className={cn(
            'h-6 border-black border-b-2 border-l-2',
            iconClassName
          )}
          type="cross"
        />
      </button>
    </div>
  );
};

export default ModalCloseButton;
