const FLEX_CENTER_ALL = 'flex justify-center items-center';
const SM_FLEX_CENTER_ALL_ADAPTIVE = 'md:flex justify-center items-center';

const BUTTON_HOVER = 'hover:bg-gray-300';
const INPUT_HOVER = 'hover:bg-gray-200';

const W_28_ADAPTIVE = 'lg:w-28 md:w-20 sm:w-16 w-14';
const H_48_ADAPTIVE = 'lg:h-48 md:h-40 sm:h-32 h-24';

export {
  FLEX_CENTER_ALL,
  SM_FLEX_CENTER_ALL_ADAPTIVE,
  BUTTON_HOVER,
  INPUT_HOVER,
  W_28_ADAPTIVE,
  H_48_ADAPTIVE,
};
