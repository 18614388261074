import background from 'src/items/video/FondFallout.mp4';

const Home = () => {
  // const isAndroid = navigator.userAgent.match(/Android/i);
  return (
    <div className="w-full h-full">
      <video className="w-full h-full bg-black" autoPlay loop muted>
        <source src={background} type="video/mp4" />
      </video>
    </div>
  );
};

export default Home;
