import { createPool } from '@vercel/postgres';
import postgresConnectionConfig from '../postgresConnectionConfig';

const getAllPlayers = async () => {
  const pool = createPool(postgresConnectionConfig);
  const response = await pool.sql`SELECT * FROM players;`;
  const players = response.rows;
  pool.end();
  return players;
};

export default getAllPlayers;
