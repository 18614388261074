import cn from 'classnames';
import ReactModal from 'react-modal';
ReactModal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Modal = ({ modal, className, children }) => {
  return (
    <ReactModal
      className={cn(
        'relative flex flex-col items-center border-2 border-black',
        className
      )}
      isOpen={modal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
