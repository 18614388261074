import { createPool } from '@vercel/postgres';
import postgresConnectionConfig from '../postgresConnectionConfig';
import { toast } from 'react-toastify';

const cleanPlayers = async () => {
  const pool = createPool(postgresConnectionConfig);

  try {
    const response = await pool.sql`TRUNCATE TABLE players;`;
    pool.end();
    return response;
  } catch (error) {
    toast.error(error);
  }
};

export default cleanPlayers;
