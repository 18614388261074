import RestrictedArea from '.';
import { PostgresDataProvider } from '../../components/PostgresDataProvider';

const RestrictedAreaContainer = () => {
  return (
    <PostgresDataProvider>
      <RestrictedArea />
    </PostgresDataProvider>
  );
};

export default RestrictedAreaContainer;
