const isDevEnv = process.env.NODE_ENV === 'development';
//check that later

const devConfig = {
  user: process.env.REACT_APP_POSTGRES_USER,
  password: process.env.REACT_APP_POSTGRES_PASSWORD,
  host: process.env.REACT_APP_POSTGRES_HOST,
  database: process.env.REACT_APP_POSTGRES_DATABASE,
  connectionString: process.env.REACT_APP_POSTGRES_URL,
};

const prodConfig = {
  user: process.env.POSTGRES_USER,
  password: process.env.POSTGRES_PASSWORD,
  host: process.env.POSTGRES_HOST,
  database: process.env.POSTGRES_DATABASE,
  connectionString: process.env.POSTGRES_URL,
};

const postgresConnectionConfig = devConfig;

export default postgresConnectionConfig;
