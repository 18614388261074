import { useEffect, useState } from 'react';
import EditableLabel from 'src/components/common/EditableLabel';
import Icon from 'src/components/common/Icon';
import Modal from 'src/components/common/Modal';
import cn from 'classnames';
import store from 'store2';
import createPlayer from 'src/utils/data/players/createPlayer';
import ModalCloseButton from './components/ModalCloseButton';
import {
  BUTTON_HOVER,
  FLEX_CENTER_ALL,
  SM_FLEX_CENTER_ALL_ADAPTIVE,
} from 'src/utils/tailwindStyles';
import mutatePlayer from 'src/utils/data/players/mutatePlayer';
import { ControlPanel, ControlPanelMobile } from '../ControlPanel';
import { SELECTED_PLAYER } from 'src/utils/constants';
import { H_48_ADAPTIVE, W_28_ADAPTIVE } from 'src/utils/tailwindStyles';

const CARDS_STYLE = {
  CARD_CONTAINER: cn('m-5 border-2 border-black', W_28_ADAPTIVE, H_48_ADAPTIVE),
};

const PlayerCard = ({
  playerData,
  updatePlayers,
  selectCharacter,
  isPlayerSelected,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const { id, name, power } = playerData;
  const isPlayerNew = playerData?.isPlayerNew;

  const [selectedPlayer, setSelectedPlayer] = useState();
  const isPlayerCanEditData = selectedPlayer === id;
  const modalInitState = isPlayerNew && isPlayerSelected && isPlayerCanEditData;

  const [nameStat, setNameStat] = useState(name);
  const [powerStat, setPowerStat] = useState(power);
  const [modal, setModal] = useState(modalInitState);

  const isPowerChanged = power !== powerStat;
  const isNameChanged = name !== nameStat;

  useEffect(() => {
    setNameStat(name);
    setPowerStat(power);
  }, [name, power]);

  useEffect(() => {
    const selectedPlayer = store.session.get(SELECTED_PLAYER);
    setSelectedPlayer(selectedPlayer);
  });

  useEffect(() => {
    setModal(modalInitState);
  }, [selectedPlayer]);

  const openModal = () => {
    if (isPlayerCanEditData) {
      setModal(true);
    }
  };

  const closeModal = () => {
    setNameStat(name);
    setPowerStat(power);
    setModal(false);
  };

  const handleSubmit = () => {
    const submittionData = {
      id,
      name: nameStat,
      power: powerStat,
    };

    const afterSubmit = () => {
      store.session.set(SELECTED_PLAYER, id);
      updatePlayers();
      if (isExpanded) {
        setModal(false);
      }
    };

    if (isPlayerNew) {
      createPlayer(submittionData).then(() => {
        afterSubmit();
      });
    } else {
      mutatePlayer(submittionData).then(() => {
        afterSubmit();
      });
    }
  };

  const isUserChangedStats = (() => {
    const condition = (() => {
      if (isPlayerNew) {
        return isNameChanged;
      } else {
        return isPowerChanged || isNameChanged;
      }
    })();

    return condition;
  })();

  const cardOnClick = () => {
    if (!isPlayerSelected) {
      selectCharacter(id);
    }
    const isWidthMobileLike = window.innerWidth < 768;
    if (isPlayerSelected && isPlayerCanEditData && isWidthMobileLike) {
      openModal();
    }
  };

  const ExpansionButton = ({ buttonClassName, iconClassName }) => {
    const expandOnClick = () => {
      setExpanded((prev) => !prev);
    };

    const ExpansionIcon = ({ classname }) =>
      isExpanded ? (
        <Icon className={classname} type="minimize" />
      ) : (
        <Icon className={classname} type="maximize" />
      );

    return (
      <button
        className={cn(
          'md:hidden w-7 h-6 p-0.5 border-black border-r-2 border-b-2',
          BUTTON_HOVER,
          FLEX_CENTER_ALL
        )}
        onClick={expandOnClick}
      >
        <ExpansionIcon classname={iconClassName} />
      </button>
    );
  };

  return (
    <>
      <div
        className={cn(
          'flex items-center justify-end flex-col',
          CARDS_STYLE.CARD_CONTAINER,
          {
            'hover:bg-green-400': !isPlayerSelected,
            'bg-green-200': !isPlayerSelected,
            'md:hover:bg-transparent md:bg-transparent hover:bg-green-400 bg-green-200':
              isPlayerCanEditData,
          }
        )}
        onClick={cardOnClick}
      >
        <div className="flex justify-between w-full">
          <div
            className={cn(
              'hidden border-black border-b-2 border-r-2 w-7 h-6',
              SM_FLEX_CENTER_ALL_ADAPTIVE
            )}
          >
            {id}
          </div>
          <div className="w-full flex justify-end">
            {isPlayerCanEditData && (
              <button
                className={cn(
                  'hidden border-b-2 md:border-l-2 border-black sm:w-full sm:h-full md:w-6 md:h-6 w-14 h-10 bg-green-200 hover:bg-green-400',
                  SM_FLEX_CENTER_ALL_ADAPTIVE
                )}
                onClick={openModal}
              >
                <Icon
                  className={cn('w-3/4 h-3/4', FLEX_CENTER_ALL)}
                  type="edit"
                />
              </button>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-end h-full w-full">
          <div
            className={cn(
              FLEX_CENTER_ALL,
              'text-center py-1 border-black border-0 md:text-6xl sm:text-4xl text-3xl w-full h-full'
            )}
          >
            {power}
          </div>
          <h2
            className={cn(
              SM_FLEX_CENTER_ALL_ADAPTIVE,
              'hidden text-center py-1 border-black border-t-2'
            )}
          >
            {name}
          </h2>
        </div>
      </div>
      <Modal
        className={cn('bg-white sm:bg-none w-36 h-64', {
          'bg-white': isPlayerNew,
          'w-full h-full': isExpanded,
        })}
        modal={modal}
      >
        <div className="flex justify-end w-full">
          <ExpansionButton />
          <ModalCloseButton
            disabled={isPlayerNew}
            buttonClassName={cn({
              'text-gray-200': isPlayerNew,
              'cursor-pointer': !isPlayerNew,
              'hover:bg-white': isPlayerNew,
            })}
            onClick={closeModal}
          />
        </div>
        <div className="h-full"></div>
        <div
          className={cn('flex justify-center w-full pb-2', {
            'h-1/4 border-black border-t-2 pb-0': isExpanded,
          })}
        >
          <EditableLabel
            inpunClassName={cn({ 'focus-visible:border-y-0': isExpanded })}
            inputValue={nameStat}
            setInputValue={setNameStat}
          />
        </div>
        {isExpanded ? (
          <ControlPanelMobile power={powerStat} setPower={setPowerStat} />
        ) : (
          <ControlPanel
            className="pb-2 h-50"
            power={powerStat}
            setPower={setPowerStat}
          />
        )}
        <div
          className={cn('flex flex-col justify-end w-full', {
            'h-2/4': isExpanded,
          })}
        >
          <button
            className={cn('border-t-2 py-1 border-black h-full', {
              'text-gray-200 border-gray-200': !isUserChangedStats,
              'bg-green-200': isUserChangedStats,
              [BUTTON_HOVER]: isUserChangedStats,
            })}
            disabled={!isUserChangedStats}
            onClick={handleSubmit}
          >
            Сохранить
          </button>
        </div>
      </Modal>
    </>
  );
};

export default PlayerCard;
