import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import Home from '../layout/Home';
import RestrictedAreaContainer from '../layout/RestrictedArea/RestrictedAreaContainer';

const isProduction = process.env.NODE_ENV === 'production';
const basename = isProduction ? process.env.PUBLIC_URL : null;

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<Home />} />
      {process.env.REACT_APP_PROTECTING_HASH && (
        <Route path={process.env.REACT_APP_PROTECTING_HASH}>
          <Route index element={<RestrictedAreaContainer />} />
        </Route>
      )}
    </Route>
  ),
  {
    basename,
  }
);

export default router;
