import { createPool } from '@vercel/postgres';
import { toast } from 'react-toastify';
import postgresConnectionConfig from '../postgresConnectionConfig';

const createPlayer = async (initialData) => {
  const { id, name, power } = initialData;

  try {
    const pool = createPool(postgresConnectionConfig);
    const response =
      await pool.sql`INSERT INTO players (id, name, power) VALUES (${id}, ${name}, ${power});`;
    pool.end();
    return response;
  } catch (error) {
    if (error.code === '23505') {
      toast.error('Такой игрок уже существует!');
      return;
    }
    if (error.code === '23514') {
      toast.error('Неверный номер игрока, или лимит игроков превышен!');
      return;
    }
  }
};

export default createPlayer;
