import store from 'store2';
import LoadingSpinner from 'src/components/common/LoadingSpinner';
import Modal from 'src/components/common/Modal';
import { ACTIONS } from 'src/components/PostgresDataProvider';
import { BUTTON_HOVER, FLEX_CENTER_ALL } from 'src/utils/tailwindStyles';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import Icon from 'src/components/common/Icon';
import cleanPlayers from 'src/utils/data/players/cleanPlayers';
import Battlefield from './components/Battlefield/Battlefield';
import ModalCloseButton from './components/cards/components/ModalCloseButton';
import PlayerCard from './components/cards/PlayerCard';
import { SELECTED_PLAYER } from 'src/utils/constants';
import { MASTER } from 'src/utils/constants';

const RestrictedArea = ({ players, status, updatePlayers }) => {
  const [isPlayerSelected, setIsPlayerSelected] = useState(false);
  const [selectedCharacter, setSelectedCharacter] = useState();
  const [clearConfirmModal, setClearConfirmModal] = useState(false);

  const [player1, player2, player3, player4, player5, player6] = players;

  const isMasterMode = selectedCharacter === MASTER;
  const isLoading =
    status === ACTIONS.LOADING &&
    status !== ACTIONS.WAIT &&
    status !== ACTIONS.GET;

  useEffect(() => {
    const selectedCharacterFromStore = store.session.get(SELECTED_PLAYER);
    if (selectedCharacterFromStore) {
      setSelectedCharacter(selectedCharacterFromStore);
    }

    const isPlayerSelected = store.session.has(SELECTED_PLAYER);
    setIsPlayerSelected(isPlayerSelected);
  });

  const cleanButtonOnClick = () => {
    setClearConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setClearConfirmModal(false);
  };

  const confirmButtonAccept = () => {
    cleanPlayers();
    updatePlayers();
    setClearConfirmModal(false);
  };

  const selectCharacter = (char) => {
    const sessionStore = store.session.set(SELECTED_PLAYER, char);
    setSelectedCharacter(sessionStore);
  };

  return (
    <div className={cn('w-full h-full', FLEX_CENTER_ALL)}>
      {isLoading ? (
        <div className={FLEX_CENTER_ALL}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className="w-full border-2 border-black">
          <div className="h-6 border-b-2 border-black flex justify-end">
            {!selectedCharacter && (
              <div className="w-full flex justify-center">
                <button
                  className={cn(
                    'border-black border-x-2 w-2/4 bg-green-200 hover:bg-green-400',
                    FLEX_CENTER_ALL
                  )}
                  onClick={() => selectCharacter(MASTER)}
                >
                  Режим ведущего
                </button>
              </div>
            )}
            {isMasterMode && (
              <button
                className={cn('border-black border-l-2', BUTTON_HOVER)}
                onClick={cleanButtonOnClick}
              >
                <Icon className="h-5" type="trash" />
              </button>
            )}
          </div>
          <div className="flex justify-between">
            <div className={cn('flex-col', FLEX_CENTER_ALL)}>
              <PlayerCard
                isPlayerSelected={isPlayerSelected}
                selectCharacter={selectCharacter}
                playerData={player1}
                updatePlayers={updatePlayers}
              />
            </div>
            <div className={cn('flex-col', FLEX_CENTER_ALL)}>
              <PlayerCard
                isPlayerSelected={isPlayerSelected}
                selectCharacter={selectCharacter}
                playerData={player2}
                updatePlayers={updatePlayers}
              />
              <PlayerCard
                isPlayerSelected={isPlayerSelected}
                selectCharacter={selectCharacter}
                playerData={player3}
                updatePlayers={updatePlayers}
              />
            </div>

            <Battlefield masterMode={isMasterMode} />

            <div className={cn('flex-col', FLEX_CENTER_ALL)}>
              <PlayerCard
                isPlayerSelected={isPlayerSelected}
                selectCharacter={selectCharacter}
                playerData={player4}
                updatePlayers={updatePlayers}
              />
              <PlayerCard
                isPlayerSelected={isPlayerSelected}
                selectCharacter={selectCharacter}
                playerData={player5}
                updatePlayers={updatePlayers}
              />
            </div>
            <div className={cn('flex-col', FLEX_CENTER_ALL)}>
              <PlayerCard
                isPlayerSelected={isPlayerSelected}
                selectCharacter={selectCharacter}
                playerData={player6}
                updatePlayers={updatePlayers}
              />
            </div>
          </div>
        </div>
      )}
      <Modal modal={clearConfirmModal}>
        <div className="w-full flex justify-end">
          <ModalCloseButton onClick={closeConfirmModal} />
        </div>
        <div className="h-full"></div>
        <div
          className={cn(
            'border-black border-t-2 w-full text-center p-1',
            FLEX_CENTER_ALL
          )}
        >
          Очистить поле?
        </div>
        <div className="flex justify-center w-full border-black border-t-2">
          <button
            className={cn(
              'border-black border-r px-2 py-1 w-full',
              BUTTON_HOVER
            )}
            onClick={confirmButtonAccept}
          >
            Да
          </button>
          <button
            className={cn(
              'border-black border-l px-2 py-1 w-full',
              BUTTON_HOVER
            )}
            onClick={closeConfirmModal}
          >
            Нет
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default RestrictedArea;
